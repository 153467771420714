@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    font-size: 16px;
    scroll-behavior: smooth;
}
.styles-module_slider__o0fqa {
    padding-bottom: 60px;
}
.pricing-plan-card,
.read-more-card {
    border: none;
    box-shadow: 0px 20px 100px rgba(242, 194, 149, 0.1);
}
@media (min-width: 425px) {
    .container {
        max-width: 100%;
    }
}
@media (min-width: 425px) {
    .container {
        max-width: 100%;
    }
}
@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
    .faqs-section.position-sticky .faqs__section-image {
        position: sticky;
        top: 40px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }

    .faqs-section.position-sticky .faqs__section-image {
        position: sticky;
        top: 80px;
    }
    .faqs-section.image-start .container {
        align-items: flex-start !important;
    }
}
@media (min-width: 991px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1199px) {
    .container {
        max-width: 1140px;
    }
    .pricing-plan-card {
        border: none;
        border-left: 1px solid rgb(247 230 248);
    }
    .card__body-items > p {
        font-size: 16px !important;
    }
    .card-section {
        box-shadow: 0px 20px 100px rgba(242, 194, 149, 0.1) !important;
    }
    .styles-module_slider__o0fqa {
        justify-content: space-evenly;
        padding: 0 30px;
        padding-bottom: 80px;
    }
}
@media (min-width: 1399px) {
    .container {
        max-width: 1240px;
    }
}
@media (min-width: 1599px) {
    .container {
        max-width: 1200px;
    }
}
section {
    width: 100%;
}
/* Main Header */
.header-section.shadowBottom {
    box-shadow: 8px 10px 80px rgba(0, 15, 55, 0.1);
}
/* Mobile Menu Section */
.menu-list-item > .active {
    background-color: rgb(247 230 248);
}
/* Interface section */
.styles-module_item-tracker__3bypy {
    gap: 15px;
}
.styles-module_item-container__a8zaY {
    margin-bottom: 50px;
}
.styles-module_item-container__a8zaY:first-of-type {
    margin-left: 20px;
}
.styles-module_item-container__a8zaY img {
    width: 100%;
    /* filter: drop-shadow(0px 20px 50px rgba(187, 0, 0, 0.03)); */

    border-radius: 20px;
    overflow: hidden;
}

.slider-image-div img {
    filter: drop-shadow(0px 20px 50px rgba(187, 0, 0, 0.03));
}
.custom-filter {
    filter: drop-shadow(0px 20px 50px rgba(187, 0, 0, 0.03));
}

/* Custom Notification Style*/
/* Define the styles for the notification box */
.notification-box {
    position: fixed;
    left: 10px;
    bottom: 12px;
    max-width: 340px;
    border-radius: 3px;
    padding: 10px 30px 10px 20px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    animation: slide-up 0.2s ease-in-out;
    z-index: 1500000;
}

/* Define the styles for the success notification box */
.notification-box.success {
    background-color: #00c02a;
    color: #fff;
}

/* Define the styles for the error notification box */
.notification-box.error {
    background-color: #f50303;
    color: #fff;
}

/* Define the styles for the warning notification box */
.notification-box.warning {
    background-color: #ffa113;
    color: #fff;
}
.notification-box.info {
    background-color: #14b9ff;
    color: #fff;
}

/* Define the animation for sliding up the notification box */
@keyframes slide-up {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.dismiss-button {
    color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 30px;
    height: 30px;
    font-size: 20px;
    background-color: transparent;
    font-weight: normal;
    border: none;
    cursor: pointer;
}
